import React from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import SocialMediaButtons from 'react-social-media-buttons';
export default function Footer() {
    return (
        <footer>
            <Container style={{ backgroundColor: "#343a40", justifyContent: "center", alignItems: "center", alignContent: "center" }} fluid>
                <Row style={{ padding: 20, justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                    <Col xs={12} sm={10} md={4} style={{justifyContent:"center",alignItems:"center",alignContent:"center"}}>
                        <SocialMediaButtons
                            links={
                                [
                                    "https://github.com/imardini98",
                                    "https://www.linkedin.com/in/iv%C3%A1n-david-mardini-garc%C3%ADa-a4bb58145/",
                                    "https://www.facebook.com/IvanMardini/",
                                    "https://www.instagram.com/ivanmardinig/",
                                    "https://twitter.com/MardiniIvan"
                                ]
                            }
                            buttonStyle={{ margin: '0px 5px', backgroundColor: '#ffffff', borderRadius: '50%', border: '2px solid #000000' }}
                            iconStyle={{ color: '#000000' }}
                            openNewTab={true}
                        />
                    </Col>
                    <Col xs={12} sm={2} md={4}>
                    <p style={{ color: 'white', fontSize: 12, textAlign: 'center', marginTop: "20px" }}>
                        &copy; Iván Mardini {1900 + new Date().getYear()}. All rights reserved
                    </p>
                    </Col>
                </Row>
            </Container>
        </footer>

    )
}
