
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Heroe from './components/Heroe';
import About from './components/About';
import Projects from './components/Projects';
import Blog from './components/Blog';
import Resume from './components/Resume';
import Contact from './components/Contact';
import Services from './components/Services';
import Footer from './components/Footer';
function App() {
  return (
    <div>
      <Header />
      <Heroe />
      <About/>
      <Projects/>
      <Footer/>
    </div>

  );
}

export default App;
