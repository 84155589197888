import { motion, useTransform, useViewportScroll } from 'framer-motion'
import React,{useEffect, useState} from 'react'
import { Image, Nav, NavDropdown } from 'react-bootstrap'
import Navbar from 'react-bootstrap/Navbar'
import logoIvan from "../assets/logo_ivan.png"
export default function Header() {
    const [navBackground, setnavBackground] = useState("transparent")
    useEffect(() => {
        const scrollCallback = () => {
            const backgroundcolor = window.scrollY > 750 ? "dark" : "transparent";
      
            setnavBackground(backgroundcolor );
          }
        document.addEventListener("scroll",scrollCallback );
        return () => {
            document.removeEventListener("scroll", scrollCallback)
        }
    })
    return (
        <Navbar collapseOnSelect expand="lg" id="home" bg={navBackground} variant="dark" fixed="top">
            <Navbar.Brand href="#">
                <motion.div whileHover={{ scale: 1.2, rotate: 90 }}
                    whileTap={{
                        scale: 0.8,
                        rotate: -360,
                        borderRadius: "100%"
                    }}><Image src={logoIvan} alt={"Iván Mardini"} roundedCircle height={50} width={50} /></motion.div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">

                </Nav>
                <Nav>
                    <motion.div whileHover={{ scale: 1.2, rotate: -5}}> <Nav.Link href="#about" style={{fontSize:24}}>About</Nav.Link></motion.div>

                    <motion.div whileHover={{ scale: 1.2, rotate: -5}}>
                        <Nav.Link  href="#projects"  style={{fontSize:24}}>
                            Projects
                        </Nav.Link>
                    </motion.div>
                    <motion.div whileHover={{ scale: 1.2, rotate: -5}}>
                        <Nav.Link  href="#blog"  style={{fontSize:24}}>
                            Blog
                        </Nav.Link>
                    </motion.div>
                    <motion.div whileHover={{ scale: 1.2, rotate: -5}}>
                        <Nav.Link  href="https://ivanmardini.com/files/Resume_2021.pdf"  style={{fontSize:24}}>
                            Resume
                        </Nav.Link>
                    </motion.div>
                    <motion.div whileHover={{ scale: 1.2, rotate: -5}}>
                        <Nav.Link  href="mailto:imardinig@gmail.com"  style={{fontSize:24}}>
                            Contact
                        </Nav.Link>
                    </motion.div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
