import { motion, useViewportScroll } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Jumbotron, Row, Fade } from 'react-bootstrap'
import profile from "../assets/foto_mywebpage.png"
import background_image from "../assets/heroe4.jpg"
import Typing from 'react-typing-animation';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
export default function Heroe() {

    return (<>

        <header className="jumbotron " style={{ backgroundImage: `url(${background_image})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", display: "block", backgroundAttachment: "fixed", height: "800px", borderRadius: 0 }}>
            <BrowserView>
                <Container style={{ justifyContent: "center", alignItems: "center", alignContent: "center" }} >
                    <Row style={{ justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                        <Col xs={12} sm={4} md={3} style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                            <Image src={profile} style={{ backgroundColor: "white", alignSelf: "center" }} roundedCircle />
                        </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", alignItems: "center", alignContent: "center",marginTop:20 }}>
                        <Col xs={12} md={10} style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                            <Typing startDelay={2000} speed={30}>
                                <h1 style={{ color: "white", fontStyle: "bold", textAlign: "center", fontSize:48 }}>Hello, <Typing.Delay ms={1000} /> my name is <span style={{color:"yellow"}}>Iván Mardini</span>.
                                    <Typing.Delay ms={1000} />
                                    I am a Full Stack Developer <Typing.Delay ms={1000} />living in Barranquilla, CO.   </h1>
                            </Typing>


                        </Col>
                    </Row>
                </Container>
            </BrowserView>
            <MobileView>
                <Container style={{ justifyContent: "center", alignItems: "center", alignContent: "center" }} >
                    <br /> <br />
                    <Row style={{ justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                        <Col xs={10} sm={4} md={3} style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                            <Image src={profile} style={{ backgroundColor: "white", alignSelf: "center" }} roundedCircle />
                        </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                        <Col xs={12} md={12} style={{ justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                            <Typing startDelay={2000} speed={30}>
                                <h1 style={{ color: "white", fontStyle: "bold", textAlign: "center" }}>Hello, <Typing.Delay ms={1000} />my name is <span style={{color:"yellow"}}>Iván Mardini</span>.
                                    <Typing.Delay ms={1000} />
                                    I am a Full Stack Developer <Typing.Delay ms={1000} />living in Barranquilla, CO.   </h1>
                            </Typing>


                        </Col>
                    </Row>
                </Container>
            </MobileView>


        </header>
        {/*  <Jumbotron  style={{ height: 500 }} style={{backgroundImage:}} >
                    <Container>
                        <Row style={{ justifyContent: "center", alignItems: "center", height: 250  }}>
                            <Col style={{ alignItems: "center", justifyContent: "center" }} xs={12} lg={4}>
                                <motion.div whileTap={{ scale: 1.3 }} style={{ cursor: "pointer" }}>
                                    <Image src={profile} width={300 } height={300 } roundedCircle />
                                </motion.div>
                            </Col>
                            <Col style={{ alignItems: "center", justifyContent: "center" }} xs={12} lg={8}>
                                {scrolled < 50 && <motion.h1 style={{ cursor: "pointer" }}
                                    animate={{ x: -50 }}
                                    initial={{ x: 50 }}
                                    transition={{ ease: "easeOut", duration: 2 }}
                                    whileTap={{ scale: 0.9 }}>
                                    Iván David Mardini García
                                </motion.h1>}
                                {scrolled > 50 && <motion.h2 style={{ cursor: "pointer" }}
                                    animate={{ x: -50 }}
                                    initial={{ x: 50 }}
                                    transition={{ ease: "easeOut", duration: 2 }}
                                    whileTap={{ scale: 0.9 }}>
                                    Iván David Mardini García
                                </motion.h2>}
                                {scrolled < 50 && <motion.div style={{ cursor: "pointer" }}
                                    animate={{ x: -50 }}
                                    initial={{ x: 50 }}
                                    transition={{ ease: "easeOut", duration: 2 }}
                                    whileTap={{ scale: 0.9 }}
                                >
                                    <p>Full Stack Developer. React | React Native | Python | Django </p>
                                </motion.div>}
                            </Col>
                        </Row>
                    </Container> 

                </Jumbotron>*/}



    </>
    )
}
