import React, {useEffect} from 'react'
export default function About() {
    return (
        <div className="container" style={{paddingTop:100, paddingBottom:100}} id="about">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                    <p>
                        I am magister in Electronic Engineering, passionate about technology.
                    </p>
                    <p>
                        {/* I am currently working as Web UI Developer at <span><a style={{ color: "blue" }} href={"https://globant.com"} target="_blank"> Globant</a></span>. */}
                        I have more than 2 years of experience working as consultant in digital platform development, across several industries, using the JavaScript/Python Stack.
                        See my previous work history on <span><a style={{ color: "blue" }} target="_blank" href={"https://www.linkedin.com/in/iv%C3%A1n-david-mardini-garc%C3%ADa-a4bb58145/?locale=en_US"}> Linkedin</a></span>.
                    </p>
                    <p>
                        Outside of work, I like to spend time with my family and take advantage of time to learn new things.
                    </p>
                    <p>
                        I am curious about Artificial Intelligence technologies. In my master thesis, I worked with Natural Language Processing models
                        to enhance reading comprehension learning in Spanish language.
                    </p>
                    <p>
                        Are you interested in working together? Don't hesitate to <span><a style={{color:"blue"}} href={"mailto:imardinig@gmail.com"}>get in touch</a></span>.
                    </p>

                </div>

            </div>

        </div>
    )
}
