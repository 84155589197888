import React from 'react'
import { Card, Button, Badge } from 'react-bootstrap'
const projects = [

    {
        "title": "Entre Líneas",
        "description": "Mobile app for reading comprehension learning that integrates an Intelligent Evaluator based on BERT (Natural Language Processing model) and an Intelligent Recommender based on Fuzzy Logic and Case-based Reasoning",
        "technologies": ["React Native", "Django", "NGINX", "ONNIX Runtime", "ReactJS", "Pytorch", "Transformers"],
        "url": "https://www.youtube.com/watch?v=75qedVCHa9g&t=1s",
        "date": "2019-2021"
    },
    {
        "title": "tConBelt",
        "description": "The tConBelt system is a solution for industrial automation, oriented to Industry 4.0. The system allows the user, through a digital platform, to perform speed control, speed sensing and management of energy consumption parameters.",
        "technologies": ["React Native", "Firebase","Python", "Raspberry Pi", "ModBus Protocol", "S7-200 PLC", "ReactJS", "HTML"],
        "url": "https://www.youtube.com/watch?v=U3bazOuqhtQ",
        "date": "2019"
    },
    {
        "title": "Wolftrack",
        "description": "Web app to track multiple vehicles location in real-time, with historical records visualization.",
        "technologies": ["HTML", "CSS", "BootStrap", "JQuery", "PHP", "Apache", "MySQL"],
        "url": "https://www.youtube.com/watch?v=6ay9cORKrZ4",
        "date": "2018"
    }
]
export default function Projects() {
    const openInNewTab = (url) => {
        if (url !== "") {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }
    return (
        <Card id="projects" style={{paddingTop:50}}>
            <br /><br />
            <Card.Body style={{ fontFamily: "Share Tech Mono" }}>
                <Card.Title>
                    <h3>Projects</h3>
                </Card.Title>
                <div className="container d-flex justify-content-center align-items-center h-100 w-100">
                    <div className="row w-100 " >
                        {projects.map((element, index) => (
                            <div className="col-12 col-md-6">
                                <Card style={{ marginTop: 20, width: "100%" }}>
                                    <Card.Header style={{ backgroundColor: "#343a40", color: "white" }}>{element.date}</Card.Header>
                                    <Card.Body>
                                        <Card.Title style={{ fontSize: 28 }}>{element.title}</Card.Title>
                                        <Card.Text style={{ fontSize: 20 }}>
                                            {element.description}
                                        </Card.Text>
                                        <div>
                                            {element.technologies.map((element, index) => (<Badge bg="dark">{element}</Badge>))}
                                        </div>
                                        <Button style={{ backgroundColor: "blue" }} onClick={() => openInNewTab(element.url)}>Watch demo</Button>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}

                    </div>

                </div>

            </Card.Body>

        </Card>
    )
}
